import React, { useEffect, useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "../../assets/scss/custom.scss";
import { requestDeleteClient, requestGetClientList } from './Middleware/action';
import { useLocation, useNavigate, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ClientsTableHeader from './ClientsTableHeader';
import { toast } from 'react-toastify';
import { useOutletContext } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import  ServerSideAdvanceTablePagination from 'components/permissions/ServerSideAdvanceTablePagination';
import * as XLSX from 'xlsx';

const Clients = () => {

    const [loggedInUserPermission] = useOutletContext();
    const dispatch = useDispatch();

    const { clients, requestInPending, requestInSuccess } = useSelector(store => store.client);

    const {control, register, handleSubmit, setValue, getValues,reset, formState: { errors } } = useForm();

    let navigate = useNavigate();
    const [allClients, setAllClients] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [addClientPermission, setAddClientPermission] = useState(false);
    const [deleteClientPermission, setDeleteClientPermission] = useState(false);
    const [addInvoicePermission, setAddInvoicePermission] = useState(false);
    const [viewInvoicePermission, setViewInvoicePermission] = useState(false);
    const [searchString,setSearchString]=useState("");
    const [perPage,setPerPage]=useState(20);
    const [pageNo,setPageNo]=useState(1);
    const [recordCount,setRecordCount]=useState(0);

    useEffect(() => {
        getClients();

        if(loggedInUserPermission.length)
        {   //console.log(loggedInUserPermission[0].delete_user);
            
            setAddClientPermission(loggedInUserPermission[0].add_client);
            setDeleteClientPermission(loggedInUserPermission[0].delete_client);
            setAddInvoicePermission(loggedInUserPermission[0].add_invoice);
            setViewInvoicePermission(loggedInUserPermission[0].view_invoice);
        }
    }, []);

    useEffect(() => {
        setLoading(true);
        if (clients && clients.length > 0) {
            setLoading(false);
            setAllClients(clients);
        }
    }, [])

    const getClients = async (searchValue="") => {
        const onSuccess = (data) => {
            setLoading(false);
            setAllClients(data.clients);
            setRecordCount(data.recordCount);
        }
        const formData ={'pageNo':pageNo,'perPage':perPage,'search':searchValue}

        dispatch(requestGetClientList({formData, onSuccess }))
    }

    const exportClient = async () =>{
        debugger
        const onSuccess = (data) => {
            debugger
            data.clients.forEach((client)=>{
                client.meta = JSON.stringify(client.meta)
            })
            const worksheet = XLSX.utils.json_to_sheet(data.clients);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
            //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
            //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
            XLSX.writeFile(workbook, "clients.xlsx");
        
        }
        const formData ={'pageNo':1,'perPage':100}

        dispatch(requestGetClientList({formData, onSuccess }))
    }

    const deleteClient = (userId) => { //console.log('userIduserId',userId);
        const onSuccess = (data) => {
            if (data.success) {

                toast.success(`Client deleted successfully`, {
                    theme: 'colored'
                });

                getClients();
            }
        }
        dispatch(requestDeleteClient({ userId, onSuccess }))
    }

    const setSearchParam = (value) =>{
        setSearchString((prev)=> {return value});

        pageChangeHandler(1, value);
    }

    const pageChangeHandler=(pageNo, searchValue="")=>{
       
        setPageNo(pageNo);
        getClients(searchValue);
    }
   
    const columns = [
        {
            accessor: 'meta.accountDetail.bussinessName',
            Header: 'Company',
        },
        {
            accessor: 'firstName',
            Header: 'First Name',
        },
        {
            accessor: 'lastName',
            Header: 'Last Name',
        },
        {
            accessor: 'phone',
            Header: 'Phone',
        },
        {
            accessor: 'joined',
            Header: 'Joined',
            Cell: (rowData) => {
                const { createdAt } = rowData.row.original;
                return createdAt?.split("T")[0];
            }
        },
        {
            accessor: 'email',
            Header: 'Email',
        },
        {
            accessor: 'none',
            Header: 'Action',
            disableSortBy: true,
            cellProps: {
                className: 'text-end'
            },
            Cell: (rowData) => {
                const { userId } = rowData.row.original;
                return (
                    <Flex>
                        <CardDropdown>
                            <div className="py-2">
                            { addClientPermission && (
                                <Dropdown.Item href="#!" onClick={(e) => { e.preventDefault(); navigate("/clients/edit/" + userId) }}><FontAwesomeIcon icon="info-circle" /> Edit</Dropdown.Item>
                                )}
                                { viewInvoicePermission && (
                                <Dropdown.Item href="#!" onClick={(e) => { e.preventDefault(); navigate("/clients/invoice/" + userId) }}><FontAwesomeIcon icon="external-link-alt" /> Invoice</Dropdown.Item>
                                )}
                                { addInvoicePermission && (
                                <Dropdown.Item href="#!" onClick={(e) => { e.preventDefault(); navigate(`/clients/${userId}/invoice/add`) }}><FontAwesomeIcon icon="external-link-alt" />Create Invoice</Dropdown.Item>
                                )}
                                 { deleteClientPermission && (
                                <Dropdown.Item href="#!" onClick={(e) => { e.preventDefault(); deleteClient(userId) }}><FontAwesomeIcon icon="trash" /> Remove</Dropdown.Item>
                                )}
                            </div>
                        </CardDropdown>
                    </Flex>
                );
            }
        }
    ];

    return (
        <AdvanceTableWrapper
            columns={columns}
            data={allClients}
            selection
            sortable
            pagination
            perPage={20}
        >
            <Card className="mb-3">
                <Card.Header>
                    <ClientsTableHeader table addClientPermission={addClientPermission} register={register} control={control} pageChangeHandler={pageChangeHandler} getValues={getValues} setValue={setValue} setSearchParam={setSearchParam} exportClient={exportClient}/>
                </Card.Header>
                <Card.Body className="p-0">
                    {isLoading ?
                        <span className="spinner-grow spinner-grow-sm ms-3"></span>
                        :
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    }
                </Card.Body>
                <Card.Footer>
                    <ServerSideAdvanceTablePagination pageChangeHandler={pageChangeHandler} totalRows={recordCount} rowsPerPage={perPage} pageNo={pageNo} setPageNo={setPageNo}  
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
};

export default Clients;
