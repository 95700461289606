import { Modal, Button, Form } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
const { useForm } = require("react-hook-form");


const TemplateModal = ({ show, handleClose, sendTemplateMessage }) => {
    const { register, handleSubmit, control, getValues, formState: { errors } } = useForm();

    const onSubmit = (data) => {
        sendTemplateMessage(data.templateName);
    }

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header
                    closeButton
                    closeVariant={null}
                    className="border-200"
                >
                    <Modal.Title as="h5">
                        <Flex alignItems="center">
                            Conversation locked. Contact the customer with a template or await their reply
                        </Flex>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="recieptDate">
                        <Form.Label>Select Template</Form.Label>

                        <Form.Select {...register('templateName', { required: 'Please select a template' })}>
                            <option value="" disabled>Select template</option>
                            <option value="hello_world">hello_world</option>
                        </Form.Select>
                        {errors.templateName && (
                            <div className="text-danger mt-2">{errors.templateName.message}</div>
                        )}

                    </Form.Group>
                </Modal.Body>
                <Modal.Footer className="border-200 d-block">
                    <IconButton
                        size="sm"
                        icon="file-pdf"
                        variant="primary"
                        iconAlign="right"
                        type="submit"
                    >
                        Send Template
                    </IconButton>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default TemplateModal;