import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Form, Row, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const CLientsTableHeader = ({ selectedRowIds,addClientPermission,register,control,pageChangeHandler,getValues,setValue,setSearchParam, exportClient }) => {

  let navigate = useNavigate();

  const [showFilter , setShowFilter] = useState(false);

  const showHideFilter=()=>{
    showFilter ? setShowFilter(false) :setShowFilter(true);      
  }

  const search =()=>{
     const name = getValues("name");

     let searchString ={name:name }

     setSearchParam(searchString);
  }

  const resetSearch= () =>{

    setValue("name","");

    let searchString =""

    setSearchParam(searchString);
  }

  return (
    <>
    <Row className="flex-between-center">
      <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">All Clients</h5>
      </Col>
      <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
        {Object.keys(selectedRowIds).length > 0 ? (
          <div className="d-flex">
            <Form.Select size="sm" aria-label="Bulk actions">
              <option>Bulk Actions</option>
              <option value="refund">Refund</option>
              <option value="delete">Delete</option>
              <option value="archive">Archive</option>
            </Form.Select>
            <Button
              type="button"
              variant="falcon-default"
              size="sm"
              className="ms-2"
            >
              Apply
            </Button>
          </div>
        ) : (
          <div id="orders-actions">
          {addClientPermission && (
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="plus"
              transform="shrink-3"
              onClick={(e)=>{e.preventDefault(); navigate("/clients/add") }}
            >
              <span className="d-none d-sm-inline-block ms-1" >New</span>
            </IconButton>
            )}
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="filter"
              transform="shrink-3"
              className="mx-2"
               onClick={ showHideFilter}
            >
              <span className="d-none d-sm-inline-block ms-1">Filter</span>
            </IconButton>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="external-link-alt"
              transform="shrink-3"
            >
              <span className="d-none d-sm-inline-block ms-1" onClick={exportClient}>Export</span>
            </IconButton>
          </div>
        )}
      </Col>
    </Row>
    { showFilter && (
    <Row>
      <Col xs={4}>
        <Col>
         <Form.Label>Name/Email/Company</Form.Label>
        </Col>
        <Col>
             <Form.Control
                type="text"
                placeholder="Name"
                {...register('name')}
              />
        </Col>
      </Col>
      <Col xs={2}>
        <Col><Form.Label>&nbsp;</Form.Label></Col>
        <Col><Button variant="primary" type="button" onClick={search}>Search</Button></Col>
      </Col>
      <Col xs={2}>
          <Col><Form.Label>&nbsp;</Form.Label></Col>
          <Col>
            <Button variant="secondary" type="button" onClick={resetSearch}>Reset</Button>
          </Col>
        </Col>
    </Row>

    )}
     </>
  );
};

CLientsTableHeader.propTypes = {
  selectedRowIds: PropTypes.object
};

export default CLientsTableHeader;
